<template>
  <div class="box">
    <Header> 实名认证 </Header>

    <div class="cen">
      <div>
        <span class="name">姓名</span>
        <span style="padding-right: 10px" v-if="userRealName.status">{{
          name1
        }}</span>
        <input v-else type="text" v-model="name" placeholder="请输入您的姓名" />
      </div>
      <div>
        <span>身份证号</span>
        <span v-if="userRealName.status" style="padding-right: 10px">{{
          identity1
        }}</span>
        <input
          v-else
          type="text"
          @input="onInput"
          v-model="identity"
          placeholder="请输入您的身份证号"
        />
      </div>
      <!-- <div>
        <span>手机号</span
        ><input type="text" v-model="tel" placeholder="请输入您的手机号" />
      </div> -->
    </div>

    <!-- <van-collapse v-model="activeNames">
      <van-collapse-item title="请选择认证方式" name="1">
        <div
          @click="typeaf(item.type)"
          v-for="(item, index) in way"
          :key="index"
          :class="add == item.type ? 'waytow' : 'way'"
        >
          {{ item.name }}
        </div>
      </van-collapse-item>
    </van-collapse> -->

    <div class="protocol" v-if="idcardStatus !== 1">
      <van-checkbox v-model="checked"></van-checkbox
      >勾选并阅读视为本人同意签署<span
        style="color: blue"
        @click="authorization"
        >《用户授权协议》</span
      >
    </div>

    <div
      :class="isIdText ? 'active' : null"
      v-if="idcardStatus !== 1"
      class="but"
      v-text="text"
      @click="but"
    >
      立即认证
    </div>
    <!-- <div v-if="idcardStatus !== 1" class="but" v-text="text" @click="but">
      立即认证
    </div> -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="title">确认认证</div>
          <div class="text">认证后不能修改，确认无误后提交</div>
          <div class="text_but">
            <div class="cancel" @click="wu">取消</div>
            <div class="notarize" @click="notarize">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 实名认证成功-----静默签协议 -->
    <!-- <van-overlay :show="become" @click="become1">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="title">协议签署</div>
          <div class="text">签署后不能修改，确认无误后签署</div>
          <div class="text_but">
            <div class="cancel" @click="wu">取消</div>
            <div class="notarize" @click="sign">确认</div>
          </div>
        </div>
      </div>
    </van-overlay> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: true,
      become: false, //静默签协议
      add: 3,
      idcardStatus: 1,
      way: [
        { name: "支付宝", type: 1 },
        { name: "腾讯云", type: 2 },
        { name: "e签宝", type: 3 },
      ],
      name: "", //名字
      identity: "", // 身份号
      //  tel: "", //  手机号
      anyIdcardadd: [],
      token: "",
      show: false, //遮罩层
      userRealName: [],
      text: "立即认证",
      activeNames: ["1"], //控制面板
      Agree: "", ///静默签
      identity1: "",
      name1: "",
      isId: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    };
  },
  computed: {
    isIdText() {
      return this.isId.test(this.identity);
    },
  },
  mounted() {
    // this.$toast.fail();
    let params = {
      token: localStorage.getItem("token"),
    };
    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.Agree = res.data.eqbAgree;
        this.idcardStatus = res.data.idcardStatus;
        if (res.data.status == 1) {
          //
        } else {
          this.$toast(res.msg);
        }
      }
    });

    let token = {
      token: localStorage.getItem("token"),
    };
    this.$api.userRealName(token).then((res) => {
      if (res.code == 0) {
        this.userRealName = res.data || {};
        var nameTwo = this.userRealName.name;
        try {
          this.name1 = nameTwo.replace(nameTwo[1], "*");
          this.name = nameTwo;
        } catch (error) {
          this.name = nameTwo;
        }
        try {
          this.identity1 = this.userRealName.idcard.replace(
            /^(.{6})(?:\d+)(.{4})$/,
            "$1****$2"
          );
        } catch (error) {
          this.identity1 = this.userRealName.idcard;
        }
        this.identity = this.userRealName.idcard;
        if (this.userRealName.status == 0) {
          this.$toast.fail("认证中,点击继续认证");
          this.text = "认证中,点击继续认证";
        } else if (this.userRealName.status == 1) {
          this.$toast.success("认证成功");
          this.text = "人脸认证成功";
          // } else if (this.userRealName.eqbAgree == 1) {
          //   this.$toast('签署完成');
          //   this.text = '签署完成';
        }
      }
    });
  },

  methods: {
    onInput(e) {
      this.$nextTick(() => {
        this.identity = e.target.value.toLocaleUpperCase();
      });
    },
    // 用户授权协议
    authorization() {
      this.$router.push("/authorization");
    },

    // 默认签署
    become1() {
      this.become = false;
    },
    // 确认签署
    sign() {
      // let params = {
      //   token: localStorage.getItem('token'),
      // };
      // this.$api.getUsergrant(params).then((res) => {
      //   if (res.code == 0) {
      //     this.become = false;
      //   } else {
      //     this.$toast(res.msg);
      //   }
      // });
    },
    wu() {
      this.show = false;
    },
    typeaf(e) {
      this.add = e;
    },
    but() {
      if (this.checked == false) {
        this.$toast("请先同意《用户授权协议》");
        return;
      }
      // 名字正则表达式
      // if (!this.name.match(/^[\u4e00-\u9fa5]{2,5}$/)) {
      //   return;
      // }
      // // 省份证号正则表达式
      if (!this.name) {
        return this.$toast("请输入姓名");
      }
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(this.identity) === false) {
        this.$toast("请输入正确身份证");
        this.show = false;
        return;
      }
      if (!this.checked) {
        return this.$toast("请同意用户授权协议");
      }

      // // 手机号正则
      // if (!this.tel.match(/^1[3,5]{1}[0-9]{1}[0-9]{8}$ /)) {
      //   return;
      // }
      if (this.text == "认证成功") {
        this.show = false;
        this.$toast.success("认证成功,请勿修改");
        // this.
      } else if (this.text == "认证中") {
        this.show = true;
        // this.$toast.fail("认证中,请不要重复认证");
      } else {
        this.show = true;
      }
    },
    notarize() {
      // 实名认证
      let params = {
        name: this.name,
        idcard: this.identity,
        token: localStorage.getItem("token"),
        // type: this.add,
        type: 5,
      };
      this.$api.anyIdcardadd(params).then((res) => {
        if (res.code == 0) {
          // this.anyIdcardadd = res.msg;
          window.location.href = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
      // let token = {
      //   token: localStorage.getItem('token'),
      // };
      // 实名认证状态
      // this.$api.userRealName(token).then((res) => {
      //   if (res.code == 0) {
      //     this.userRealName = res.data || {};
      //     var nameTwo = this.userRealName.name;
      //     // // let yin = nameTwo.replace(/(?<=.)./g, "**");
      //     this.name = nameTwo;
      //     // // 身份证号
      //     // let id = this.userRealName.idcard;
      //     // var strcard = id.replace(
      //     //   /^(.{4})(?:\d+)(.{2})$/,
      //     //   "$1*************$2"
      //     // );
      //     this.identity = strcard;

      //     if (this.userRealName.status == 0) {
      //       this.$api.userRealName(token).then((res) => {
      //         window.location.href = res.data.originalUrl;
      //       });
      //     } else if (this.userRealName.status == 1) {
      //       this.$toast.success('已完成实名认证');
      //     }
      //   }
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.protocol {
  display: flex;
  margin-top: 10px;
  margin-left: 17px;
  font-size: 14px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 282px;
  height: 132px;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  font-size: 15px;
  .title {
    margin-top: 20px;
    font-weight: 600;
  }
  .text {
    font-size: 13px;
    line-height: 32px;
  }
  .text_but {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    div {
      width: 80px;
      height: 28px;
      border: 1px solid #000000;
      line-height: 28px;
      text-align: center;
      font-size: 13px;
      border-radius: 5px;
    }
    .notarize {
      background-color: black;
      color: #ffffff;
      margin-right: 32px;
    }
    .cancel {
      margin-left: 32px;
    }
  }
}
.box {
  height: 100vh;
  background-color: #f3f4f8;
  .cen {
    width: 341px;
    background-color: #ffffff;
    margin-left: 17px;
    border-radius: 5px;
    div {
      width: 324px;
      display: flex;
      justify-content: space-between;
      padding-left: 17px;
      line-height: 47px;
      border-bottom: 1px solid #f8f8f8;
    }
    span {
      font-size: 13px;
      font-weight: 600;
    }
    input {
      font-size: 13px;
      border: 0;
      width: 244px;
    }
  }
}
.but {
  width: 341px;
  height: 40px;
  background-color: #eeeeee;
  font-size: 15px;
  color: #666666;
  text-align: center;
  line-height: 40px;
  margin-left: 17px;
  margin-top: 40px;
  border-radius: 3px;
  &.active {
    background: #0754d3;
    color: #fff;
  }
}

.topHeader {
  margin-bottom: 20px;
}
/deep/.van-collapse-item {
  width: 341px;
  margin-left: 17px;
  margin-top: 20px;
  border-radius: 5px;
}

.way {
  height: 40px;
  // background-color: aqua;
  color: #000000;
  font-size: 14px;
}

.waytow {
  height: 40px;
  // background-color: aqua;
  color: red;
  font-size: 16px;
  font-weight: 600;
}
</style>
